.table-wrapper {
    @apply bg-white dark:bg-dark-1 dark:text-white pb-3;
}
.custom-table {
    @apply w-full;

    thead tr {
        @apply font-medium border-b dark:border-dark;

        td {
            @apply py-3 px-5 lg:px-8 text-grey-5;
        }
    }

    tbody {
        @apply divide-y dark:divide-dark text-grey-7;

        tr {
            @apply relative hover:bg-grey-1 cursor-pointer dark:hover:bg-dark ;

            td {
                @apply p-5 lg:px-8;
            }

            &.spinner {
                @apply hover:bg-inherit dark:hover:bg-inherit cursor-default;
                td {
                    @apply text-center; text-align: center !important;
                }
            }

            div.float-buttons {
                @apply hidden absolute top-5 2xl:top-4 right-24 space-x-2 bg-white;
            }

            &:hover div.float-buttons {
                @apply lg:block;
            }
        }
    }

    &.shadow-hover tr {
        @apply hover:bg-inherit hover:shadow;
    }

    &.no-hover tr {
        @apply hover:bg-inherit dark:hover:bg-inherit cursor-default;
    }

    &.no-border tr {
        @apply border-none;
    }

    &.stripe {
        tr:nth-child(odd)  {
            @apply bg-primary-light dark:bg-dark;
        }
    }

    &.recent {
        @apply border;
        thead tr {
            @apply bg-grey-0.5 #{!important};
        }
    }
}
